import React, { useEffect } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const useChatForm = false;

// the following is used only if Chat Form enabled
const FW_ID = 72000002802;
const onFreshDesk = () => {
    console.log("FreshDesk loaded", typeof window.FreshworksWidget);
    let FreshworksWidget = window.FreshworksWidget;
    if (typeof FreshworksWidget != "function") {
        console.warn("Freshworks Widget not loaded");
        return
    }
    FreshworksWidget('open', 'ticketForm');
}
const waitFreshDesk = () => {
    setTimeout(onFreshDesk, 300);
}

// and this is only for Contact Form
const formUrl = "https://emerald-help.freshdesk.com/widgets/feedback_widget/new?widgetType=embedded&searchArea=no"
    + "&formTitle="+encodeURIComponent("Support Form")
    + "&submitThanks="+encodeURIComponent("Thank you for reaching out to us. An Emerald representative will contact you as soon as possible.")
;

const Page = () => {
  useEffect(() => {

      setTimeout(() => {
        window.fwSettings={ 'widget_id': FW_ID };
        const script = document.createElement('script');
        script.async = true;
        if (useChatForm) {
            script.src = `https://widget.freshworks.com/widgets/${FW_ID}.js`;
            script.addEventListener('load', waitFreshDesk);
        } else {
            script.src = `https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js`;
        }
        document.body.appendChild(script);
      }, 500)

    return () => {}
  })
  return (
    <Layout>
      <Seo title="Support" />
      <section className="support">
        <h2>
          <span>Contact</span> Support
        </h2>
        <div id="support-form" style={{height: 1000}}>
            <iframe title="Feedback Form"
                    className="freshwidget-embedded-form"
                    id="freshwidget-embedded-form"
                    src={formUrl}
                    scrolling="no" height="750px" width="100%"
                    frameBorder="0" >
            </iframe>
        </div>
      </section>
    </Layout>
  )
}

export default Page
